import * as _ from 'lodash'

const initialState = {
  // id: 0,
  // firstName: '',
  // lastName: '',
  // ldap: '',
  // accessType: 0,
  // emailAddress: '',
  // mailingAddress: '',
  // branch: '',
  // avatar: {
  //   base: 0,
  //   background: '#7E4AA7',
  //   shirt: '#FFFFFF',
  //   skinTone: '#F7D8D0',
  //   face: 1,
  //   hair: {
  //     type: 0,
  //     color: '#ECE6E6'
  //   },
  //   facialHair: {
  //     type: 0,
  //     color: '#ECE6E6'
  //   },
  //   accessories: {
  //     type: [],
  //     color: '#EE4234'
  //   }
  // },
  // homeStyle: 0
}

export const userNames = {
  setUser: 'user/setUser',
  setHomeStyle: 'user/setHomeStyle',
  setAvatar: 'user/setAvatar',
  resetUser: 'user/resetUser'
}

export default {
  state: () => initialState,
  mutations: {
    [userNames.setUser] (state, user) {
      _.assign(state, user)
    },
    [userNames.setHomeStyle] (state, homeStyle) {
      state.homeStyle = homeStyle
    },
    [userNames.setAvatar] (state, avatar) {
      state.avatar = avatar
    },
    [userNames.resetUser] (state) {
      console.log('resetUser', state)
      _.assign(state, initialState)
      console.log('reseted User', state)
    }
  }
}
