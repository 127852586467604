import { userClient } from "@/api/clients";

const endpoints = {
  // USER
  getMyUser: "/me",
  updateMyUser: "/me",
  deleteMyUser: "/me",
  getUsers: "",
  getUsersByRegion: (region) => `/regions/${region}`,
  createUser: "",
  getUser: (id) => `/${id}`,
  updateUser: (id) => `/${id}`,
  deleteUser: (id) => `/${id}`,

  // AVATAR
  createMyAvatar: "/avatar",
  getMyAvatar: "/avatar",
  updateMyAvatar: "/avatar",
  deleteMyAvatar: "/avatar",
  updateMyAccessories: "/avatar/accessories",
  createAvatar: (id) => `/${id}/avatar`,
  getAvatar: (id) => `/${id}/avatar`,
  updateAvatar: (id) => `/${id}/avatar`,
  deleteAvatar: (id) => `/${id}/avatar`,
  setAvatarAccessories: (id) => `/${id}/avatar/accessories`,

  // HOME-STYLE
  getMyHomeStyle: "/home-style",
  updateMyHomeStyle: "/home-style",
  getHomeStyle: (id) => `/${id}/home-style`,
  updateHomeStyle: (id) => `/${id}/home-style`,
};

export default class UserAPI {
  static async getMyUser() {
    const response = await userClient.get(endpoints.getMyUser);
    return response.data;
  }

  static async updateMyUser(user) {
    const response = await userClient.put(endpoints.updateMyUser, { role:user.role });
    return response.data;
  }

  static async deleteMyUser() {
    const response = await userClient.delete(endpoints.deleteMyUser);
    return response.data;
  }

  static async getUsers() {
    const response = await userClient.get(endpoints.getUsers);
    return response.data;
  }

  static async getUsersByRegion(region) {
    const response = await userClient.get(endpoints.getUsersByRegion(region));
    return response.data;
  }

  static async createUser(user) {
    // working!
    const response = await userClient.post(endpoints.createUser, user);
    return response.data;
  }

  static async getUser(id) {
    const response = await userClient.get(endpoints.getUser(id));
    return response.data;
  }

  static async updateUser(user) {
    // working!
    const response = await userClient.put(endpoints.updateUser,  user );
    return response.data;
  }

  static async deleteUser(id) {
    const response = await userClient.delete(endpoints.deleteUser(id));
    return response.data;
  }

  static async createMyAvatar(avatar) {
    const response = await userClient.post(endpoints.createMyAvatar, avatar);
    return response.data;
  }

  static async getMyAvatar() {
    const response = await userClient.get(endpoints.getMyAvatar);
    return response.data;
  }

  static async updateMyAvatar(avatar) {
    const response = await userClient.put(endpoints.updateMyAvatar, avatar);
    return response.data;
  }

  static async deleteMyAvatar() {
    const response = await userClient.delete(endpoints.deleteMyAvatar);
    return response.data;
  }

  static async updateMyAccessories(accessories) {
    const response = await userClient.put(endpoints.updateMyAccessories, {
      accessories,
    });
    return response.data;
  }

  static async createAvatar(id, avatar) {
    const response = await userClient.post(endpoints.createAvatar(id), avatar);
    return response.data;
  }

  static async getAvatar(id) {
    const response = await userClient.get(endpoints.getAvatar(id));
    return response.data;
  }

  static async updateAvatar(id, avatar) {
    const response = await userClient.put(endpoints.updateAvatar(id), avatar);
    return response.data;
  }

  static async deleteAvatar(id) {
    const response = await userClient.delete(endpoints.deleteAvatar(id));
    return response.data;
  }

  static async updateAvatarAccessories(id, accessories) {
    const response = await userClient.put(endpoints.setAvatarAccessories(id), {
      accessories,
    });
    return response.data;
  }

  static async getMyHomeStyle() {
    const response = await userClient.get(endpoints.getMyHomeStyle);
    return response.data;
  }

  static async updateMyHomeStyle(homeStyle) {
    console.log(homeStyle);
    const response = await userClient.put(endpoints.updateMyHomeStyle, {
      id: homeStyle,
    });
    return response.data;
  }

  static async getHomeStyle(id) {
    const response = await userClient.get(endpoints.getHomeStyle(id));
    return response.data;
  }

  static async updateHomeStyle(id, homeStyle) {
    console.log(homeStyle);
    const response = await userClient.put(endpoints.updateHomeStyle(id), {
      homeStyle,
    });
    return response.data;
  }
}
