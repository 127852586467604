import { createRouter, createWebHistory, useRoute, useRouter } from 'vue-router'
import { routes } from '@/router/routes'
// import { useRouter, useRoute } from 'vue-router'

import AuthAPI from '@/api/AuthAPI'
import { useCookies } from '@vueuse/integrations/useCookies'

const { get } = useCookies(['onboarding_journey'], { doNotParse: false, autoUpdateDependencies: false })
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// router.useCookies()
router.beforeEach(async (to) => {
  const loggedIn = get('onboarding_journey')
  if (!loggedIn && window.location.pathname !== '/login') window.location.pathname = '/login'
})

export default router
