<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script setup>
import { useStore } from "vuex";
import AuthAPI from "@/api/AuthAPI";
import UserAPI from "@/api/UserAPI";
import ChecklistAPI from "@/api/ChecklistAPI";
import { userNames } from "@/store/modules/user";
import { avatarNames } from "@/store/modules/avatar";
import { useRouter } from "vue-router";
const router = useRouter();
const store = useStore();
// VALIDATE AND GET USER
(async () => {
  let avatar;
  let user;
  try {
    user = await UserAPI.getMyUser();
    user.leader = false
    const checklist = await ChecklistAPI.getChecklist();
    console.log("checklist:", checklist);
  } catch (e) {
    console.log(e);
  }
  store.commit(userNames.setUser, user);
  try {
    avatar = await UserAPI.getMyAvatar();
    console.log("avatar@:", avatar);
    if (avatar.id) {
      // user.avatar = avatar
      await store.commit(avatarNames.setAvatar, avatar);
      console.log("storeAvatar:", store.state.avatar);
      // store.commit(userNames.setUser, user);
    }
  } catch (e) {
    console.log(e);
  }
  // const avatar = await UserAPI.getMyAvatar()
  // console.log('avatar:', avatar)
  // user.avatar = avatar
  // store.commit(userNames.setUser, user);
  try {
    console.log("user:", store.state.user);
    // REDIRECTS
    if (!user.home_style) {
      await router.push("/personalize/select-house");
    } else if (!avatar.id) {
      await router.push("/personalize/avatar-builder");
    }
  } catch (error) {
    console.error("Invalid token");
  }
})();
</script>

<style>
@import url("assets/vendor/HelveticaNeueLtStd.css");
@import url("assets/vendor/CabinSketch.css");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: #f4f6f5;
  font-size: 14px;
  font-family: "Helvetica Neue Lt Std", Arial, sans-serif;
  color: #5f6562;
}

button,
input,
select,
textarea {
  font-family: "Helvetica Neue Lt Std", Arial, sans-serif;
}

p {
  line-height: 1.3em;
}

/* ANIMATION */

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter-active {
  transition-delay: 0.25s;
}
.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
</style>
