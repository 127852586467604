import axios from 'axios'

export const userClient = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/users`,
  withCredentials: true
})

export const authClient = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/auth/init`,
  withCredentials: true
})

export const checklistClient = axios.create({
  baseURL: `${process.env.VUE_APP_URL}`,
  withCredentials: true
})

export const progressClient = axios.create({
  baseURL: `${process.env.VUE_APP_URL}`,
  withCredentials: true
})

export const resourceClient = axios.create({
  baseURL: `${process.env.VUE_APP_URL}`,
  withCredentials: true
})
