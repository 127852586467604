import { checklistClient } from '@/api/clients'

const endpoints = {
  // CHECKLISTS
  createChecklist: '/checklists',
  getChecklist:  `/checklists`,
  updateChecklist:  `/checklists`,
  deleteChecklist:  `/checklists`,

  // TASKS
  createTask: '/tasks',
  getTask:  `/tasks`,
  updateTask:  `/tasks`,
  deleteTask:  `/tasks`,

  assistance: '/attention',
  // SUBTASKS
  createSubtask: '/subtasks',
  getSubtask:  `/subtasks`,
  updateSubtask:  `/subtasks`,
  deleteSubtask:  `/subtasks`
}

export default class ChecklistAPI {
  static async createChecklist (checklist) {
    const response = await checklistClient.post(endpoints.createChecklist, checklist)
    return response.data
  }

  static async getChecklist () {
    const response = await checklistClient.get(endpoints.getChecklist)
    return response.data
  }
  static async updateAssistance (payload) {
    const response = await checklistClient.post(endpoints.assistance, {...payload} )
    return response.data
  }

  static async updateChecklist (id, checklist) {
    const response = await checklistClient.put(endpoints.updateChecklist(id), checklist)
    return response.data
  }

  static async deleteChecklist (id) {
    const response = await checklistClient.delete(endpoints.deleteChecklist(id))
    return response.data
  }

  static async createTask (checklistID, task) {
    const response = await checklistClient.post(endpoints.createTask(checklistID), { ...task, checklistID })
    return response.data
  }

  static async getTask (id) {
    const response = await checklistClient.get(endpoints.getTask(id))
    return response.data
  }

  static async updateTask (task) {
    const response = await checklistClient.put(endpoints.updateTask, {...task})
    return response.data
  }

  static async deleteTask (id) {
    const response = await checklistClient.delete(endpoints.deleteTask(id))
    return response.data
  }

  static async createSubtask (taskID, subtask) {
    const response = await checklistClient.post(endpoints.createSubtask(taskID), { ...subtask, taskID })
    return response.data
  }

  static async getSubtask (id) {
    const response = await checklistClient.get(endpoints.getSubtask(id))
    return response.data
  }

  static async updateSubtask (subtask) {
    const response = await checklistClient.put(endpoints.updateSubtask, {...subtask})
    return response.data
  }

  static async deleteSubtask (id) {
    const response = await checklistClient.delete(endpoints.deleteSubtask(id))
    return response.data
  }
}
